import React from "react";
import styles from './Gradeschool.module.scss';
import Header from "../../components/Header";

const Gradeschool = () => {
    return (
        <div className={styles.mainPage}>
            <Header />
            <div className={styles.body}>
                <div className={styles.banner}>
                    <img src="/assets/academics_banner_2.jpg" alt="Gradeschool" />
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.intro}>
                        <p>
                            A whole new paradigm of learning
                        </p>
                        <p>
                            The grade school at Shishyaa started from June 2016. It welcomed the year with two students and has already grown to more than 50 students.
                        </p>
                        <p>
                            The versatile curriculum address all the aspects that contribute to wholesome learning for the child.
                        </p>
                        <p>
                            Addressing these capacities, and building each of them allows the child to flourish in all aspects of life and not just academics.
                            The sense of fulfillment and acquired confidence in turn makes them perform well in academics as well. Doing well becomes a habit.
                            Curriculum details can be found <a href="/curriculum" className={styles.curriculumLink}>here</a>
                        </p>
                    </div>
                    <div className={styles.curriculumBody}>
                        <div className={styles.curriculumContent}>
                            <div className={styles.splitContent}>
                                <div className={styles.textContent}>
                                    <p>
                                        A growing child achieves different milestones at different stages of the schooling years.
                                        In the grades, the student journeys from the imaginary world of early childhood to the adult world of awakened thoughts.
                                        The creative methodology and unique curriculum of Waldorf education enhances each student’s ability to learn while passing through each stage of child development.
                                        The Waldorf school curriculum strives to appreciate the changing abilities of the growing child in a very concrete way by offering subject matter specifically
                                        suited to the appropriate developmental level.
                                    </p>

                                </div>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_7.png" alt="Curriculum" />
                                </div>
                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_10.png" alt="Curriculum" />
                                </div>
                                <div className={styles.textContent}>
                                    <p>
                                        Throughout each grade, the students encounter a stimulating depth of experience through the arts.
                                        This includes movement, foreign language, handwork (knitting, crochet, sewing), painting, beeswax or clay modeling, music, drawing, games and drama.
                                        The arts are integrated into the entire academic curriculum, including math and science.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.specialSubjectsBody}>
                        <div className={styles.heading}>Special Subjects</div>
                        <div className={styles.subjectsContent}>
                            <div className={styles.splitContent}>
                                <div className={styles.textContent}>
                                    <p>
                                        <strong>Handwork</strong> - The Handwork curriculum is broad and includes skills such as knitting, crocheting,
                                        hand sewing, embroidery, felting, paper crafts, weaving, pattern design and machine sewing.
                                        Many of the benefits of the Handwork program are obvious: hand-eye coordination; the ability
                                        to understand and follow a process from concept to completion; and the ability to focus on a
                                        project for an extended period of time, and reading a pattern, including codes and abbreviations.
                                        There are more subtle rewards that complement these obvious benefits. Design and colour choice
                                        allow for individual creative expression.
                                    </p>

                                </div>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_6.png" alt="Curriculum" />
                                </div>
                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_8.png" alt="Curriculum" />
                                </div>
                                <div className={styles.textContent}>
                                    <p>
                                        <strong>Movement/Games</strong> - Joyful and responsible movement is at the heart of Waldorf physical education.
                                        Teachers encourage age- appropriate activities in class and during recess.
                                        Activities and movement change from year to year, consistent with the development of the child.
                                        They help to build physical agility and a sense of internal rhythm while developing spatial
                                        awareness and coordination. The cognitive impacts of these activities have a lasting and
                                        positive effect on overall health and well being, as well as supporting the ability to
                                        organize and process intellectual concepts.
                                    </p>
                                </div>
                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.textContent}>
                                    <p>
                                        <strong>Music</strong> - In a Waldorf School, Music is woven through the curriculum and
                                        creates a familiar rhythmic foundation for the education of the child. Music also plays
                                        an important part in school festivals, plays, and assemblies. When students enter the Grades,
                                        in addition to singing daily with their Main Lesson teacher, students participate in Music Lessons,
                                        where their innate sense of musicality is thoughtfully cultivated through stories, games, harmony,
                                        listening, reading, and working together in a group.
                                    </p>
                                </div>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_9.png" alt="Curriculum" />
                                </div>
                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_11.png" alt="Curriculum" />
                                </div>
                                <div className={styles.textContent}>
                                    <p>
                                        <strong>Art</strong> - Painting, drawing and modeling, allows students to link deeper meaning
                                        to lesson content, builds focus and encourages creativity.
                                        Time spent on visual arts also improves fine motor skills and fuels young imaginations.
                                    </p>
                                </div>
                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.textContent}>
                                    <p>
                                        <strong>Drama</strong> - All students have parts in an annual class play based on a main lesson subject area.
                                        For every grade, the class play is an important part of the Waldorf curriculum,
                                        with roles chosen to develop each student’s innate gifts and to support curricular themes
                                        for that year. You will witness the high level of speech work that is cultivated and
                                        watch skills develop over the years.
                                    </p>

                                </div>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_12.png" alt="Curriculum" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gradeschool;
