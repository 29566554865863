import React from "react";
import styles from './Mission.module.scss';
import Header from "../../components/Header";


const Mission = () => {
  return (
      <div className={styles.mainPage}>
        <Header />
        <div className={styles.body}>
          <div className={styles.missionPageContent}>
            <div className={styles.missiongPageBanner}>
              <img src="/assets/banner_2.png" alt="banner" />
            </div>
            <div className={styles.missionContentOne}>
              <div className={styles.philosophyTitle}>Our Philosophy</div>
              <div className={styles.philosophyContent}>
                <div className={styles.text}>
                  <p>
                    Children are born intelligent.
                    'Knowledge creation' is a lifeskill that empowers
                    every child to confidently face all challenges and
                    uncertainties that life brings forth, at every
                    stage in life.
                  </p>
                  <p>
                    With the right amount of facilitation, children
                    find within them this ability to create knowledge
                    through observation, exploration, reflection
                    and assimilation.
                  </p>
                  <p>
                    Our prime responsibility is to help every child
                    discover and master this ability hidden within
                    them and prepare them for their independent
                    journeys.
                  </p>
                </div>
                <div className={styles.image}>
                  <img src="/assets/shutterstock_298417388-min.jpeg" alt="content "/>
                </div>
              </div>
            </div>
            <div className={styles.missionContentTwo}>
              <div className={styles.missionTitle}>Our Mission</div>
              <div className={styles.content}>
                <div className={styles.image}>
                  <img src="/assets/shutterstock_68400973-min.jpeg" alt="earth"/>
                </div>
                <div className={styles.text}>
                  <p>
                    To nurture every child into self-assured, confident
                    and happy individuals by providing them a
                    stimulating environment that is rich with
                    opportunities to explore, enquire, evaluate and
                    express, and a space that is rich in faith and freedom.
                  </p>
                  <p>
                    We raise rational, informed, responsible and
                    independent adults, who willingly take it upon them to
                    share, care and protect the global family.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.missionContentThree}>
              <div className={styles.text}>
                <p>
                  Shishyaa is a much loved school in Vashi, Navi Mumbai. We are several years old and are steadily growing every year.
                  Who could have imagined how a "coffee-table-conversation" about a school that our founders would love to have would transform into Shishyaa.
                  As they say, "If you really want something, the universe conspires to make it happen".
                </p>
              </div>
              <div className={styles.image}>
                <img src="/assets/missionConversation.png" alt="conversation" />
              </div>
            </div>
            <div className={styles.missionContentFour}>
              <div className={styles.image}>
                <img src="/assets/missionHistory.png" alt="history" />
              </div>
              <div className={styles.text}>
                <p>
                  In 2013, Ms. Vimala Nandakumar, the ex-principal of
                  one of the institutions that the three founders once
                  taught in, wrote the book ‘What Ails Our Schools?’
                  based on her experiences in different institutions,
                  nationally as well as internationally. The book pens the
                  shortcomings and limitations of the existing
                  education system in India, and the negative impact it
                  has on the students.
                </p>
                <p>
                  A discussion over this book sparked the idea of a
                  'small school' that is purely centered and designed on
                  the needs of children. With the seed sown, the action
                  to transform this idea into a reality began.
                </p>
                <p>
                  The synergy created by the determined nature of each
                  member, the firm conviction in their souls, the body of
                  experience they carried with them, and their faith in
                  the vision, became the fuel for this long yet passionate
                  journey. Innumerable meetings, school visits,
                  research, consultations, discussions and
                  brainstorming sessions led to the GENESIS of
                  ‘Shishyaa’, a small school that is committed to being a
                  haven for the children seeking true education.
                  And so after a tenacious journey, this dream has
                  become a reality. Shishyaa remains thankful for your
                  good wishes and blessings. We know that it will take
                  Shishyaa from strength to strength
                </p>
              </div>
            </div>
            <div className={styles.missionContentFive}>
              <img width="100%" height="auto" src="/assets/missionPageQuote.png" alt="quote" />
            </div>
          </div>
        </div>
      </div>

  )
}


export default Mission;
