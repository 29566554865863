import React from "react";
import {useHistory} from "react-router-dom";
import styles from "./OverlayNavigation.module.scss";


const OverlayNavigation = ({backgroundPath, navigateTo, altText}) => {
    const history = useHistory();

    const routeChange = (event) => {
        event.stopPropagation();
        history.push(navigateTo);
    };

  return (
    <div className={styles.overlayNavigationContainer}>
      <div className={styles.imageContainer} onClick={routeChange}>
        <img src={backgroundPath} alt={altText} />
      </div>
    </div>
  )
};

export default OverlayNavigation;