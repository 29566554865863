import React from "react";
import styles from "./Story.module.scss";
import Header from "../../components/Header";


const Shishya_Story = () => {
    return (
        <div className={styles.mainPage}>
            <Header />
            <div className={styles.pageHeader}>
                About Shishyaa
            </div>
            <div className={styles.mainContent}>
                <h3>The school over the years</h3>
                <p className={styles.textContent}>
                    The story of Shishyaa Waldorf School began in 2016 when two senior
                    educators came together to start the first Waldorf school in Navi Mumbai.
                    The need arose as they realized the necessity for a child centric
                    approach towards education and not one based on rote learning.
                    They wanted the school to make learning fun, enjoyable, yet meaningful,
                    enriching and fulfilling. They wanted to create a learning environment
                    that is stress-free and which brings a positive impact on the mind,
                    body and spirit. Infact, a conversation about ‘how a school should be’
                    over a cup of coffee that our founders had finally transformed into
                    Shishyaa. As they say, "If you really want something, the universe
                    conspires to make it happen".
                </p>
                <p className={styles.textContent}>
                    Waldorf Education was initiated by Austrian philosopher Rudolf Steiner
                    more than 100 years ago based on the principles of Anthroposophy.
                    To know more about Waldorf Education, you can go through
                    <a href={"https://www.waldorf-100.org/en/"} target={"_blank"} rel={"noreferrer"}>https://www.waldorf-100.org/en/</a>.
                    Shishyaa believes in the principles of Anthroposophy, and the curriculum at Shishyaa is centred around the same.

                </p>
                <p className={styles.textContent}>
                    The school, in sync with the Waldorf Education philosophy, doesn’t have
                    a ‘one size fits all’ approach towards children and their education.
                    It believes in meeting the child where the child is, and walking
                    the journey alongside them, helping them learn through an enlivened
                    curriculum. Students experience day to day life in a meaningful way,
                    by participating and 'doing' purposeful activities. All
                    learning of concepts and subjects is achieved in a holistic
                    fashion ensuring students understand them conceptually, and
                    not though rote learning. The school empowers the students
                    to become confident and socially responsible individuals as they grow.
                </p>
                <p className={styles.textContent}>
                    The school welcomed its first student in the year 2016 and slowly
                    and gradually grew from the humble beginning, adding one grade
                    at a time. Presently, the school has about 80 students from
                    pre-school till Grade 8 (first batch), which will gradually
                    and eventually move to Grade 10.
                </p>
                <p className={styles.textContent}>
                    As the school progressed, so did the number of teachers. Starting
                    with 2 of the founders, the school presently has more than 15 teachers
                    across various areas and subjects. The school has also designed
                    and implements specialized teacher training programs to enable
                    the teachers to be fully aware of the framework and methodology
                    of Waldorf Education and realize their full potential.
                    To enrich the student’s learning experiences the school acknowledges
                    the role of parents and home life. Thus, the school also conducts
                    parent training programs that help parents gain insights into
                    Waldorf Education, its philosophy and the health nurturing forces
                    of its child development perspective, thereby ensuring the overall
                    wellbeing of the children.
                </p>
                <p className={styles.textContent}>
                    Impressions and expressions are a part of our daily existence.
                    When rightly understood, they become a tool of education for the
                    teachers. Age-appropriately curated curriculum with abundant
                    opportunities of soulful expressions mark the learning experiences
                    of children here and ‘Art’ is at the centre of all such experiences.
                </p>
                <p className={styles.textContent}>
                    The planet that we live on is a gift that we leave behind for our
                    future generations. Shishyaa believes that it is our responsibility
                    to help children become morally and ethically responsible for the
                    wellbeing of this planet. This wisdom is not drilled into them in
                    dialogue, but inculcated through every day living. Every single day
                    the school makes choices that are earth friendly and sustainable.
                    Right from the furniture, to the furnishings and learning materials,
                    all are either environmentally abundant, recycled, upcycled or
                    refurbished. The school uses self-designed cane furniture for the
                    classrooms. Old wardrobe doors have been converted into tables.
                    Pre-used cupboards are upcycled by the teachers and students together
                    to be used in classrooms. Sarees received as contributions from
                    parents are transformed into curtains for the classrooms.
                    Toys are lovingly handmade by teachers and parents.
                </p>
                <p className={styles.textContent}>
                    Students reach out to their own families and neighbours to collect
                    old delipidated buckets and other plastic wares and use them to plant
                    flower and vegetable trees with the intention of harvesting the abundant
                    sunshine that graces the campus every day. The school houses a patch
                    of healthy soil that has been retained to incorporate a gardening
                    culture in children. Children at Shishyaa have sown, grown and
                    harvested various crops of rice, wheat, maize, ladyfingers,
                    brinjals, mustard, coriander, spinach, chillies, etc. that also
                    becomes part of the meals cooked in the preschool. The berry tree,
                    during its fruiting season is excitedly frequented by all children
                    during breaks, just as it should be. The bee-friendly plants welcome
                    bees and butterflies that occupy children’s fancy and narratives for
                    days at length. Life cycles of snails, butterflies and moths in
                    their natural habitats are sought and witnessed by curious eyes.
                    'Learning' is a living experience for children at Shishyaa.
                </p>
                <p className={styles.textContent}>
                    The soil in the school is nourished through the school’s composting
                    practices as well as through the Bio-dynamic farming culture of
                    the school. Festivals and events are organised to lend this rich
                    environment and experience to outside children who are deprived
                    of this kind of abundance in the city.
                </p>
                <p className={styles.textContent}>
                    All the practices of the school are guided by the thought
                    that education is not outside of life, but a part of life.
                    One cannot isolate education from meaningful day-to-day living.
                    Infact, right education prepares children not only for an exam
                    but for life too.
                </p>
                <p className={styles.textContent}>
                    The school would be looking at affiliating with CAIE offering
                    the IGCSE Program.
                </p>
                <p className={styles.textContent}>
                    If you believe that your child needs to be in a school which
                    gives wings to their creativity and inner self, helps them grow
                    as conscious individuals, where they are received in reverence
                    and educated in love, where the focus is on learning by doing
                    and truly understanding concepts and not merely rote learning
                    their way to an exam, then this is the school for your child.
                </p>
                <p className={styles.textContent}>
                    To know more about the school, click
                    <a href={"https://drive.google.com/file/d/1Vd3cczLScfv8erRcohrTQo590NG_OoWF/view?usp=sharing"}
                       target={"_blank"} rel={"noreferrer"}> here
                    </a> to go through the detailed presentation.
                </p>
                <h3 className={styles.missionTitle}>Our Mission</h3>
                <p className={styles.textContent}>
                    Our mission is to
                </p>
                <ul className={styles.list}>
                    <li className={styles.bulletPoints}>
                        Nurture every child into self-assured, confident and
                        happy individuals by providing
                        them a stimulating environment that is rich with
                        opportunities to explore, enquire, evaluate and express,
                        and a space that is rich in faith and freedom.
                    </li>
                    <li className={styles.bulletPoints}>
                        Raise rational, informed, responsible and independent adults,
                        who willingly take it upon them to share, care and protect
                        the global family.
                    </li>
                </ul>
                <h3 className={styles.philosophyTitle}>Our Philosophy</h3>
                <p className={styles.textContent}>
                    The guiding philosophy of the school
                </p>
                <ul className={styles.list}>
                    <li className={styles.bulletPoints}>
                        Children are born intelligent. 'Knowledge creation' is a
                        lifeskill that empowers every child to confidently face all
                        opportunities, challenges and uncertainties that life brings
                        forth, at every stage in life.
                    </li>
                    <li className={styles.bulletPoints}>
                        With the right amount of facilitation, children find within
                        them this ability to create knowledge through observation,
                        exploration, reflection and assimilation.
                    </li>
                    <li className={styles.bulletPoints}>
                        Our prime responsibility is to help every child discover
                        and master this ability hidden within them and prepare
                        them for their independent journeys.
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default Shishya_Story;
