import React from "react";
import {Navigation, Pagination, Autoplay} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";

import styles from "./Carousel.module.scss";


const Carousel = ({images}) => {
    return (
        <Swiper
            slidesPerView={1}
            modules={[Navigation, Pagination, Autoplay]}
            navigation
            pagination={{clickable: true}}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false
            }}
            centeredSlides={true}
        >
            {
                images.map((img, index) => {
                    return (
                        <SwiperSlide className={styles.imgContainer} key={index}>
                            <img src={img} alt={"Shishyaa"} />
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    )
}

export default Carousel;
