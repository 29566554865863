import React from "react";
import Header from "../../components/Header";
import styles from './Farming.module.scss';

const Farming = () => {
    return (
        <div className={styles.mainPage}>
          <Header />
          <div className={styles.body}>
            <div className={styles.banner}>
              <img src="/assets/farming_banner.jpg" alt="Farming banner" />
            </div>
            <div className={styles.mainContent}>
              <div className={styles.text}>
                <p>
                  Shishyaa Farm is a botanical garden in itself. Different varieties of vegetation and flowers are a delight to watch and tend to.
                </p>
                <p>
                  It is also a micro ecological world blueprint.
                  Butterflies, dragonflies, sparrows, earthworms, millipedes, snails, etc., are all residents of our farm.
                  We visit them often and they are most delighted to see us.</p>
                <p>
                  They tell stories about what they do, where they live, what they like to eat, how they are born,
                  how they grow, etc., during our children's daily visits to the farm.
                </p>
              </div>
              <div className={styles.images}>
                <img src="/assets/caterpillar.jpg" alt="caterpillar" />
                <img src="/assets/farm-2_orig.gif" alt="fruits" />
                <img src="/assets/farming.jpg" alt="plantation" />
                <img src="/assets/eggs_1_orig.gif" alt="flowers" />
              </div>
            </div>
          </div>
        </div>
    )
};

export default Farming;