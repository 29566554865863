import React from "react";
import Header from "../../components/Header";
import styles from './Significance.module.scss';


const Significance = () => {
    return (
        <div className={styles.mainPage}>
            <Header />
            <div className={styles.body}>
                <div className={styles.banner}>
                    <img width="100%" height="100%" src="/assets/significance_banner.png" alt="banner" />
                </div>
                <div className={styles.mainContent}>
                    <p>
                        Most people today have realized that something is amiss in conventional education.
                        Indeed it is. Therefore it is heartening to know that in the global conversation about what is the right
                        way of educating children, people are discussing “new” solutions that are similar to Waldorf
                        education: a move away from early, pressure-cooker academics to more time outside in nature,
                        the importance of handwriting, longer-term relationships of teachers with a class of students, less
                        emphasis on standardized testing, less screen time, more collaboration among teachers, to name
                        just a few.
                    </p>
                    <p>
                        None of these are flash-in-the-pan ideas. They have both research and experience to validate them.
                        Yet none of these ideas are new within the Waldorf environment. Waldorf Education enjoys a long
                        history and tradition. It is 100 years old!! And yet it is as fresh and relevant today as ever,
                        to the child, as well as to humanity.
                    </p>
                    <strong>A Classical Education for the 21st Century</strong>
                    <p>
                        Creativity, communication, collaboration, critical thinking and problem solving — these are the skills
                        so many parents want their children to develop today. In the Waldorf environment, these are among
                        the capacities we have always worked to uncover and nurture in each student.
                    </p>
                    <p>
                        Perhaps most important in all this is the fact that our curriculum is taught through a lens of social
                        collaboration and compassion. Waldorf students graduate with a well-rounded education,
                        intellectual maturity, confidence in their abilities and a sense of community and responsibility.
                    </p>
                </div>
                <div className={styles.quote}>
                    <img width="100%" height="100%" src="/assets/significance_quote.png" alt="quote" />
                </div>
            </div>
        </div>
    )
};

export default Significance;