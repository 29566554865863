import React from "react";
import Header from "../../components/Header";
import styles from "./Events.module.scss";
import {Desktop, Mobile} from "../../ui/responsive";


const Events = () => {

  return (
    <div className={styles.mainPage}>
      <Header />
      <div className={styles.body}>
        <div className={styles.eventsContent}>
          <h3 className={styles.eventTitle}>Hasta Rachana Mela 2023</h3>
          <Desktop>
            <div className={styles.imgContainer}>
              <img src="/assets/hastarachana_landscape-min.png" alt={"Hastarachana 2023"} />
            </div>
          </Desktop>
          <Mobile>
            <div className={styles.imgContainer}>
              <img src="/assets/hastarachana_portrait-min.png" alt={"Hastarachana 2023"} />
            </div>
          </Mobile>
          <div className={styles.eventContent}>
            <p className={styles.eventText}>
              HastaRachana Mela, an annual mega-event organized and presented
              by Shishyaa Waldorf School in Navi Mumbai, is a manifestation
              of all that Waldorf education represents. It is a potpourri of
              handcrafted exhibits, workshops facilitating handwork, classical
              and folk ethnic cultural performances that soothe the senses,
              collaborative and traditional games for children, and food
              from different parts of India that connects us to our cultural
              heritage, and more. The experience is truly delivered through
              the head, heart, and hands of the visitors.

              The Mela brings to life a rich experience for children and adults
              alike, evoking wonder and curiosity in the process of life.
            </p>
            <p><b>Save the Date:</b> December 9 & 10, 2023.</p>
          </div>

          {/*<h3 className={styles.eventTitle}>Exploring Waldorf Education (EWE)</h3>*/}
          {/*<div className={styles.imgContainer}>*/}
          {/*  <img src="/assets/event_summer_camp.jpeg" alt={"Exploring Waldorf"} />*/}
          {/*</div>*/}
          {/*<div className={styles.eventContent}>*/}
          {/*  <p className={styles.eventText}>*/}
          {/*    Shishyaa Waldorf School presents a 2-week session (10th to 21st April,*/}
          {/*    Monday to Friday, 9 AM to 12 NOON) for children (between age 2-6) and*/}
          {/*    parents to get a sneak peek into the world of Waldorf Education.*/}
          {/*    Waldorf education works on the principle of developing and nourishing*/}
          {/*    the entire being of your child - intellectual, physical, emotional*/}
          {/*    and spiritual. EWE would focus mainly on pre-school education with*/}
          {/*    children at the core of it. Here, children would be free to explore,*/}
          {/*    children are learning by doing and doing by learning, children are*/}
          {/*    loved, cared and happy. Come, explore the world of Waldorf education.*/}
          {/*    Prior registration is required. There are no charges for attending*/}
          {/*    the session, i.e. it is absolutely free for children and parents.*/}
          {/*    You can call Madhuri (99875 48749) to register yourself or register*/}
          {/*    using the link <a href={"https://bit.ly/40D7hc8"} target={"_blank"} rel={"noreferrer"}>here</a>*/}
          {/*  </p>*/}
          {/*</div>*/}

          {/*<table className={styles.table}>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td colSpan={3} className={styles.headerCol}>*/}
          {/*      Events List*/}
          {/*    </td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td colSpan={3} className={styles.headerCol}>*/}
          {/*      2021-2022*/}
          {/*    </td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td colSpan={1} className={styles.headerCol}>Date</td>*/}
          {/*    <td colSpan={1} className={styles.headerCol}>Day</td>*/}
          {/*    <td colSpan={1} className={styles.headerCol}>Festival</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td colSpan={1}>13.08.2021</td>*/}
          {/*    <td colSpan={1}>Friday</td>*/}
          {/*    <td colSpan={1}>Nagpanchami</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td colSpan={1}>15.08.2021</td>*/}
          {/*    <td colSpan={1}>Sunday</td>*/}
          {/*    <td colSpan={1}>Independence Day</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td colSpan={1}>19.08.2021</td>*/}
          {/*    <td colSpan={1}>Thursday</td>*/}
          {/*    <td colSpan={1} className={styles.nestedTableContainer}>*/}
          {/*      <table className={styles.nestedTable}>*/}
          {/*        <tr>*/}
          {/*          <td colSpan={3}>*/}
          {/*            Moharram*/}
          {/*          </td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td colSpan={3}>Onam</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td colSpan={3}>Rakshabandhan</td>*/}
          {/*        </tr>*/}
          {/*      </table>*/}
          {/*    </td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>28.08.2021</td>*/}
          {/*    <td>Saturday</td>*/}
          {/*    <td>Janmashtami</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>10.09.2021</td>*/}
          {/*    <td>Friday</td>*/}
          {/*    <td>Ganesh Chaturthi</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>14.10.2021</td>*/}
          {/*    <td>Thursday</td>*/}
          {/*    <td>Dassera</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>30.10.2021</td>*/}
          {/*    <td>Saturday</td>*/}
          {/*    <td>Diwali</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>15.11.2021</td>*/}
          {/*    <td>Monday</td>*/}
          {/*    <td>Children's Day</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>18.11.2021</td>*/}
          {/*    <td>Thursday</td>*/}
          {/*    <td>Guru Nanak Jayanti</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>23.12.2021</td>*/}
          {/*    <td>Thursday</td>*/}
          {/*    <td>Christmas</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>14.01.2022</td>*/}
          {/*    <td>Thursday</td>*/}
          {/*    <td className={styles.nestedTableContainer}>*/}
          {/*      <table className={styles.nestedTable}>*/}
          {/*        <tr><td>Lohri</td></tr>*/}
          {/*        <tr><td>Makar Sankrati</td></tr>*/}
          {/*      </table>*/}
          {/*    </td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>26.01.2022</td>*/}
          {/*    <td>Wednesday</td>*/}
          {/*    <td>Republic Day</td>*/}
          {/*  </tr>*/}
          {/*  <tr className={styles.mainTableRow}>*/}
          {/*    <td>18.03.2022</td>*/}
          {/*    <td>Friday</td>*/}
          {/*    <td>Holi</td>*/}
          {/*  </tr>*/}
          {/*</table>*/}
        </div>
      </div>
    </div>
  )
};

export default Events;
