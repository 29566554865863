import React from "react";
import styles from "./CollapsibleSection.module.scss";


const CollapsibleSection = ({title, children}) => {
    return (
        <details className={styles.detailsContainer}>
            <summary className={styles.summaryContainer}>{title}</summary>
            <hr className={styles.divider} />
            <div className={styles.contentContainer}>
                {children}
            </div>
        </details>
    )
}

export default CollapsibleSection;
