import React from "react";
import styles from "./Education.module.scss";
import Header from '../../components/Header';

const Education = () => {
    return (
        <div className={styles.mainPage}>
            <Header />
            <div className={styles.body}>
              <div className={styles.banner}>
                  <img src="/assets/Education_header.jpg" alt="Why Education" />
              </div>
                <div className={styles.introText}>
                    Education systems today are under scrutiny from all quarters.
                    Educators, industries and society in general, are all questioning the purpose, the process and the outcome of the present education system.
                    All this, through sheer observation and imagination, without us coming in the way.
                </div>
                <div className={styles.contentOne}>
                    <div className={styles.splitContent}>
                        <div className={styles.textContent}>
                            <p>
                                Why Handwriting Is Still Essential in the Keyboard Age - It is more than just a motor skill,
                                researchers say. It engages the mind.
                            </p>
                            <a href="http://well.blogs.nytimes.com/2016/06/20/why-handwriting-is-still-essential-in-the-keyboard-age/" target="_blank" rel="noreferrer">http://well.blogs.nytimes.com/2016/06/20/why-handwriting-is-still-essential-in-the-keyboard-age/</a>
                        </div>
                        <div className={styles.imageContent}>
                            <img src="/assets/education_photo_1.jpg" alt="Education" />
                        </div>
                    </div>
                    <div className={styles.splitContent}>
                        <div className={styles.textContent}>
                            <p>
                                The New York Times: How to Raise a Creative Child. Step One: Back Off.
                                Child prodigies rarely become adult geniuses who change the world.
                                Originality is difficult to encourage but easy to thwart.
                            </p>
                            <a href="http://www.nytimes.com/2016/01/31/opinion/sunday/how-to-raise-a-creative-child-step-one-back-off.html" target="_blank" rel="noreferrer">
                                http://www.nytimes.com/2016/01/31/opinion/sunday/how-to-raise-a-creative-child-step-one-back-off.html
                            </a>
                        </div>
                        <div className={styles.imageContent}>
                            <img src="/assets/education_photo_2.jpg" alt="reading" />
                        </div>
                    </div>
                    <div className={styles.splitContent}>
                        <div className={styles.imageContent}>
                            <img src="/assets/education_photo_3.jpg" alt="reading" />
                        </div>
                        <div className={styles.textContent}>
                            <p>
                                Why story telling is the most powerful communication:
                                <br />
                                <a href="http://lifehacker.com/5965703/the-science-of-storytelling-why-telling-a-story-is-the-most-powerful-way-to-activate-our-brains" target="_blank" rel="noreferrer">
                                    http://lifehacker.com/5965703/the-science-of-storytelling-why-telling-a-story-is-the-most-powerful-way-to-activate-our-brains
                                </a>
                            </p>
                            <p>
                                Risk is essential to childhood - as are scrapes, grazes, falls and panic.<br />
                                <a href="http://www.theguardian.com/commentisfree/2015/oct/14/risk-essential-childhood-children-danger" target="_blank" rel="noreferrer">
                                    http://www.theguardian.com/commentisfree/2015/oct/14/risk-essential-childhood-children-danger
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className={styles.splitContent}>
                        <div className={styles.textContent}>
                            <p>
                                Dalai Lama <br />
                                <a href="http://timesofindia.indiatimes.com/india/Change-education-system-to-improve-humans-innerqualityDalaiLama/articleshow/49731429.cms" target="_blank" rel="noreferrer">
                                    http://timesofindia.indiatimes.com/india/Change-education-system-to-improve-humans-innerqualityDalaiLama/articleshow/49731429.cms
                                </a>
                            </p>
                            <p>
                                Obama, Michelle Obama, Hirschi <br />
                                <a href="http://timesofindia.indiatimes.com/lifestyle/relationships/parenting/School-gardenshelp-kids-learn-better-and-eathealthier/articleshow/49333144.cms" target="_blank" rel="noreferrer">
                                    http://timesofindia.indiatimes.com/lifestyle/relationships/parenting/School-gardenshelp-kids-learn-better-and-eathealthier/articleshow/49333144.cms
                                </a>
                            </p>
                            <p>
                                Sherry Turkle, an MIT psychologist and author
                                of numerous books on the negative social
                                effects of technology, including "Alone
                                Together" and "Reclaiming Conversation," has
                                an explanation for this seeming hypocrisy:
                                Those at tech's bleeding edge know full well
                                how dangerous products can be.
                                <br />
                                <a href="https://finance.yahoo.com/news/mit-psychologist-explains-why-many-174200521.html" target="_blank" rel="noreferrer">
                                    https://finance.yahoo.com/news/mit-psychologist-explains-why-many-174200521.html
                                </a>
                            </p>
                        </div>
                        <div className={styles.imageContent}>
                          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/wX78iKhInsc"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen title="Sir Ken Robinson"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Education;