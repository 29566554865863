import React from "react";
import styles from './Preschool.module.scss';
import Header from "../../components/Header";

const Preschool = () => {
    return (
        <div className={styles.mainPage}>
            <Header />
            <div className={styles.body}>
                <div className={styles.banner}>
                    <img src="/assets/preschool_header.jpg" alt="Preschool Banner" />
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.intro}>
                        At Shishyaa, we call this stage of learning and self discovery in a child, 'The Wonder Years' and ensure
                        that we keep it alive during these magical years. Being imitators, children of this age are like dry
                        sponges that absorb everything in their environment. Our curriculum allows the pupil to see, do and
                        learn from the teacher, through real and result-bearing 'doing' activities.
                    </div>
                    <div className={styles.curriculumBody}>
                        <div className={styles.heading}>Curriculum</div>
                        <div className={styles.curriculumContent}>
                            <div className={styles.splitContent}>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_1.png" alt="Curriculum" />
                                </div>
                                <div className={styles.textContent}>
                                    <ul>
                                        <li className={styles.turquoiseBullet}>Singing of rhymes and verses</li>
                                        <li className={styles.blueBullet}>Dance and movement</li>
                                        <li className={styles.pinkBullet}>Painting</li>
                                        <li className={styles.turquoiseBullet}>Handwork</li>
                                        <li className={styles.blueBullet}>Gardening</li>
                                        <li className={styles.pinkBullet}>Outdoor and indoor free play</li>
                                        <li className={styles.turquoiseBullet}>Cooking and housekeeping</li>
                                        <li className={styles.blueBullet}>Storytelling and puppet show</li>
                                        <li className={styles.pinkBullet}>Festival celebrations</li>
                                        <li className={styles.turquoiseBullet}>Nature trails</li>
                                        <li className={styles.blueBullet}>Observation and interaction</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={styles.highlightContent}>
                              <p>The curriculum is designed to follow a daily, weekly, and seasonal rhythm.</p>
                              <p>Special emphasis is laid on the naturally-occurring changes in nature.</p>
                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.textContent}>
                                    <strong>Outdoor Learning</strong>
                                    <p>
                                        The outdoors are an integral part of our
                                        curriculum. It is during these sessions that
                                        children truly learn and teach themselves.
                                        Exploring the environment, understanding
                                        climates and observing nature work towards
                                        creation, is the easiest way to make children
                                        fall in love with our planet. It will only be natural
                                        for them to want to protect it then. Movement,
                                        gardening, nature walks, and such other
                                        activities help children experience life in its
                                        wholesomeness.
                                    </p>
                                </div>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_2.png" alt="outdoor learning" />
                                </div>
                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_3.png" alt="indoor learning" />
                                </div>
                                <div className={styles.textContent}>
                                    <strong>Indoor Learning</strong>
                                    <p>
                                        The time spent indoors provide learning
                                        opportunities through singing, story telling,
                                        cooking, handwork, housekeeping and many
                                        more such activities that allow children to do
                                        things they already want to do in a way they
                                        love. Being with a teacher and observing her do
                                        these things shows them the right way to do it.
                                    </p>
                                </div>

                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.textContent}>
                                    <strong>Freeplay</strong>
                                    <p>
                                        Children aspire to be 'grown-ups.' They admire
                                        and observe what the elders around them do.
                                        They absorb these actions and look for
                                        opportunities to imitate and practice them. Free
                                        play, particularly indoors, provides the platform
                                        for these children to live these learnings and
                                        observations. Enacting their elders, expressing
                                        themselves freely are the stepping stones
                                        towards language building, articulation and
                                        communication.
                                    </p>
                                </div>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_4.png" alt="Freeplay" />
                                </div>
                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_5.png" alt="Ambience" />
                                </div>
                                <div className={styles.textContent}>
                                    <strong>Ambience</strong>
                                    <p>
                                        We create a beautiful and ambient environment
                                        for the children to be in. The classrooms are
                                        softly lit with simple wooden furniture
                                        resembling their homes, making them feel
                                        secure. 'Form' follows 'function'... and therefore
                                        the classroom settings are completely flexible to
                                        accommodate all creative inspirations of
                                        children during indoor 'freeplay'.
                                    </p>
                                </div>

                            </div>
                            <div className={styles.splitContent}>
                                <div className={styles.textContent}>
                                    <strong>Toys</strong>
                                    <p>
                                        Natural objects such as pine cones, shells, hand-
                                        made dolls, traditional clothes, wooden toys,

                                        and anything that catches the fancy of our little
                                        ones, becomes a toy in our classroom. Playing
                                        with natural objects, children gradually become
                                        aware, of the beauty and wholesomeness of
                                        nature. Using the same things in different ways,
                                        renews perspectives and also inculcates skills of
                                        reuse and recycle, which are the most important
                                        maxims for the future years.
                                    </p>
                                </div>
                                <div className={styles.imageContent}>
                                    <img src="/assets/academics_photo_6.png" alt="toys" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preschool;
