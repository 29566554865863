import React from "react";
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import styles from './Navigation.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SocialIcon} from "react-social-icons";


const Navigation = () => {

    return (
        <div className={styles.navigationContainer}>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
              <Navbar.Brand href="/">
                <div className={styles.foundationName}>Baal Prakriti Foundation's</div>
                <img src="/assets/shishyaa_logo.svg" alt="Shishyaa School, Navi Mumbai" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link className={styles.homeBtn} href="/">Home</Nav.Link>
                  <NavDropdown title="Shishyaa" id="collasible-nav-dropdown-one">
                    <NavDropdown.Item href="/glimpses">Glimpses Of Shishyaa</NavDropdown.Item>
                    <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                    <NavDropdown.Item href="/philosophy">Our Philosophy</NavDropdown.Item>
                    <NavDropdown.Item href="/admission">Admission</NavDropdown.Item>
                    <NavDropdown.Item href="/founders">Founders</NavDropdown.Item>
                    <NavDropdown.Item href="/faculty">Faculty</NavDropdown.Item>
                    <NavDropdown.Item href="/significance">Imagination is key</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Academics" id="collasible-nav-dropdown-two">
                    <NavDropdown.Item href="/preschool">Preschool</NavDropdown.Item>
                    <NavDropdown.Item href="/gradeschool">Gradeschool</NavDropdown.Item>
                    <NavDropdown.Item href="/farming">Farming</NavDropdown.Item>
                    <NavDropdown.Item href="/why_education">Education being questioned</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/events">Upcoming Events</Nav.Link>
                  <div className={styles.mediaContainer}>
                    <SocialIcon url={"https://www.facebook.com/shishyaaschool/"} className={styles.mediaIcon} />
                    <SocialIcon url={"https://www.instagram.com/shishyaawaldorfschool/"} className={styles.mediaIcon} />
                    <SocialIcon url={"https://www.youtube.com/@shishyaa-awaldorfschool"} className={styles.mediaIcon} />
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Navigation;
