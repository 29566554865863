import React from "react";
import styles from './Header.module.scss';
import Navigation from '../Navigation';
import {Desktop, Mobile} from "../../ui/responsive";


const Header = () => {
    return (
        <div className={styles.header}>
            <Desktop>
                <div className={styles.headerLine1}>
                    <div className={styles.headerTitle}>
                        Waldorf Education
                    </div>
                    <div className={styles.headerAddress}>
                        Silicon Tower, Plot No. 46, Sector 30A, Vashi, Navi Mumbai-400705
                    </div>
                    <div className={styles.headerContact}>
                        Contact: 9930220983 / 9821626003 / 9321217435 / 9821032641 / 9987548749
                    </div>
                </div>
                <div className={styles.headerLine2}>
                    <Navigation />
                </div>
            </Desktop>
            <Mobile>
                <div className={styles.headerLine2}>
                    <Navigation />
                </div>
            </Mobile>
            {/*<Tablet>*/}
            {/*    <div className={styles.headerLine2}>*/}
            {/*        <Navigation />*/}
            {/*    </div>*/}
            {/*</Tablet>*/}
        </div>

    )
};

export default Header;
