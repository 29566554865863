import React from "react";
import { useHistory } from "react-router-dom";
import styles from './Homepage.module.scss';
import Header from '../../components/Header';
import OverlayNavigation  from '../../components/OverlayNavigation';
import Carousel from "../../components/Carousel";


const CAROUSEL_IMAGES  = [
  "/assets/carousel_1.jpeg",
  "/assets/carousel_2.jpg",
  "/assets/carousel_3.jpeg",
  "/assets/carousel_4.jpg",
  "/assets/carousel_5.jpeg",
]

const Homepage = () => {
    const history = useHistory();

    const navigateToPreschool = () => {
        history.push("/preschool");
    };

    const navigateToGradeschool = () => {
        history.push("/gradeschool");
    }

    return (
        <div className={styles.mainPage}>
            <Header />
            <div className={styles.body}>
              <div className={styles.mainPageSectionOne}>
                <div className={styles.homePageCoverPhoto}>
                  {/*<img src="/assets/cover_photo.jpg" alt="Cover" />*/}
                  <Carousel images={CAROUSEL_IMAGES} />
                </div>
                <div className={styles.homePageAlternateNavigation}>
                  <OverlayNavigation
                    backgroundPath="/assets/home_page_glimpses_tab.png"
                    navigateTo="/glimpses"
                    altText="Glimpses of Shishyaa"
                  />
                  <OverlayNavigation
                    backgroundPath="/assets/home_page_philosophy_tab.png"
                    navigateTo="/philosophy"
                    altText="Waldorf Philosophy"
                  />
                  <OverlayNavigation
                    backgroundPath="/assets/home_page_events_tab.png"
                    navigateTo="/events"
                    altText="Events"
                  />
                </div>
              </div>
              <div className={styles.mainPageSectionTwo}>
                <div className={styles.aboutUsSection}>
                  <div className={styles.blueSubtitle}>
                    About Us
                  </div>
                  <div className={styles.ourMissionSection}>
                    <div className={styles.missionContentLeftPane}>
                      <h4>Our Mission</h4>
                      <p className={styles.bodyTextContent}>
                        To nurture every child into self-assured,
                        confident and happy individuals by providing
                        them a stimulating environment that is rich with
                        opportunities to explore, enquire, evaluate and
                        express, and a space that is rich in faith and
                        freedom.
                      </p>
                      <p className={styles.bodyContent}>
                        We raise rational, informed, responsible and
                        independent adults, who willingly take it upon
                        them to share, care and protect the global
                        family. To know more about us, please check
                        out our presentation
                        <a href={"https://drive.google.com/file/d/1Vd3cczLScfv8erRcohrTQo590NG_OoWF/view?usp=sharing"} target={"_blank"} rel={"noreferrer"}> here</a>
                      </p>
                    </div>
                    <div className={styles.missionContentRightPage}>
                      <img src="/assets/mission_right_image.jpg" alt="Mission" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mainPageSectionThree}>
                <div className={styles.curriculumSectionTitle}>
                  <span className={styles.curriculumSectionTitleHeader}>Academics</span>
                  <span className={styles.curriculumSectionSubtitle}>
                    A living curriculum that keeps children engaged and interested in all
                    that they do by meeting their soul needs in an appropriate manner thereby
                    ensuring that education never becomes a drudgery. This approach ensures
                    well being of the child at all levels: physical, emotional, psychological
                    and intellectual. That is why school continues to remain a rich and joyful
                    experience for children at Shishyaa.
                  </span>
                </div>
                <div className={styles.curriculumSectionBody}>
                  <div className={styles.curriculumLeftPage} onClick={navigateToPreschool}>
                    <div className={styles.curriculumLeftPaneTitle}>
                      <span>Preschool</span>
                    </div>
                    <div className={styles.curriculumLeftPaneBody}>
                      <div className={styles.curriculumBodyImage}>
                        <img src="/assets/homepage_hand.jpeg" alt="hand print"/>
                      </div>
                    </div>
                  </div>
                  <div className={styles.curriculumRightPage} onClick={navigateToGradeschool}>
                    <div className={styles.curriculumRightPaneTitle}>
                      <span>Gradeschool</span>
                    </div>
                    <div className={styles.curriculumRightPaneBody}>
                      <div className={styles.curriculumBodyImage}>
                        <img src="/assets/homepage_heart.jpeg" alt="heart shape"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mainPageSectionFour}>
                <div className={styles.mainPageSectionFourLeftPane}>
                  <img src="/assets/main_page_kids_drawing.jpg" alt="kids drawing"/>
                </div>
                <div className={styles.mainPageSectionFourRightPane}>
                  <span className={styles.mainPageSectionFourTitle}>Explore Admissions</span>
                  <a href="mailto:shishyaa.asmallschool@gmail.com" className={styles.mainPageAdmissionButton}>Write to us</a>
                </div>
              </div>
            </div>
        </div>
    )
};

export default Homepage;
