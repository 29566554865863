import React from "react";
import styles from "./Founders.module.scss";
import Header from '../../components/Header/Header';


const Founders = () => {
  return (
    <div className={styles.mainPage}>
      <Header/>
      <div className={styles.body}>
        <div className={styles.founderTitle}>Founders</div>
        <div className={styles.teamIntroductionText}>
            <p>
                Shishyaa is co-founded by passionate educationists who have come together to create
                this school that fulfils their vision of what a school stands for. Shishyaa stands to bridge the
                chasm created by the limiting nature of many of the practices of conventional education
                systems, and the restrictive world-view that it gives the students.
            </p>
            <p>The school is being actively managed by the founders - Ms. Bandana Basu and Ms. Reena Dhila.</p>
        </div>
        <div className={styles.founderDetails}>
            <span className={styles.title}>Ms. Bandana Basu</span>
            <span className={styles.qualification}>Master of Commerce, Bachelor of Education</span>
            <img src="/assets/bandana_basu_founder.png" alt="Ms. Bandana Basu" />
            <div className={styles.bio}>
                <p>
                    A life of purpose is a celebration of life itself !
                </p>
                <p>
                    Ms. Bandana always enjoyed the company of children, and so the potent
                    combination of purpose and profession became her inner wellspring. With a
                    career spanning more than two and a half decades in schools across different
                    boards, she is also an education consultant, a school inspector, a school
                    leadership trainer, while also being a teacher trainer. She has also partnered
                    with a prestigious UK organisation EDT, on an ‘Educational Research Project’
                    that was simultaneously piloted in Egypt, India and Kenya. She is also a child
                    development adviser and has been on the advisory panel for world renowned
                    children’s food brand Kellogs.
                </p>
                <p>
                    Before co-founding Shishyaa, she headed an IB World school as the Founder
                    Dy. Director, managing its operations from its inception. Also anchoring
                    the responsibility of being the IB Diploma Programme Coordinator, she
                    fructified the ‘IBO authorisation and affiliation’ process for the school.
                    Alongside, she also taught both the international curricula IB Diploma
                    Programme and CAIE (IGCSE & A Levels). Shishyaa furthered this repertoire
                    by bringing in Waldorf Education, and today she is both a teacher as well
                    as a trainer. Driven by the purpose of making education a happy and thereby
                    more effective process for children, her deep engagement and understanding
                    of children's need has given birth to a published collection of poems that
                    throw light on, and address the soul development of children.
                    'In My Heart Plays a Song' is a collection of poems that speak to children
                    in a rich and meaningful way and is a must-have for parents who wish to
                    connect with their children in a traditionally joyful way.
                </p>
            </div>
        </div>
        <div className={styles.founderDetails}>
            <span className={styles.title}>Ms. Reena Dhila</span>
            <span className={styles.qualification}>Masters of Arts, Bachelors of Education</span>
            <img src="/assets/reena.jpeg" alt="Ms. Reena Dhila" />
            <div className={styles.bio}>
                <p>
                    Ms. Reena had been the Vice Principal of an international school
                    offering CIE programmes.After 20 years of managing education,
                    and teaching at schools, her passion for Waldorf Education has
                    led her to intensively train as a Waldorf teacher and advocate
                    Waldorf education, through Shishyaa. There was always a special
                    place in her heart for the art of teaching and she relished the joys
                    of learning, guiding and mentoring young minds. A naturalist at
                    her core, her heart beats for all things pure and innocent.
                    Needless to say she spent most of her time either amidst nature
                    or in the company of children.
                </p>
                <p>
                    Reena’s journey has been nothing short of an adventure of a
                    lifetime, complete with experiences and learning that helped
                    realize her dream. A dream to nurture children in the purest
                    form, to carve the future of mankind by shaping green minds.
                    She is giving children the blessing of bearing souls that remain
                    attuned to nature and the will to do what they believe is right.
                </p>
                <p>
                    Left on her own, Reena would love to daydream in the lap of
                    nature. Sometimes chasing rainbows, running behind butterflies
                    and getting lost in the beauty of nature. Nature is her best friend
                    and she makes sure that all her children become friends with
                    nature too! Spend time in nature and appreciate its purity and
                    abundance.
                </p>
                <p>
                    M.A. B.Ed. by qualification, trained in Bio-Dynamic farming, a
                    poet and writer, she bears the soul of a child and the heart of
                    nature!
                </p>
            </div>
        </div>

      </div>
    </div>
  )
};

export default Founders;
