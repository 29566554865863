import React from "react";
import './scss/index.scss';
import Content from "./utils/content";


function App() {
  return (
    <div className="App" id="outer-container">
      <Content />
    </div>
  );
}

export default App;
