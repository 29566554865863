import React from "react";
import styles from './TeamListings.module.scss';


const TeamListings = ({memberDetails}) => {

    return (
        <React.Fragment>
            {
                memberDetails.map((member, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className={styles.teamMember}
                            key={index}>
                            <div className={styles.memberPhoto}>
                                <img src={member.imgSrc} alt={member.imgAltDescr} />
                            </div>
                            <div className={styles.memberDescription}>
                                <div className={styles.memberName}>{member.name}</div>
                                <div className={styles.memberRole}><strong>Role: </strong>{member.role}</div>
                                <div className={styles.memberQualification}><strong>Qualification: </strong>{member.qualification}</div>
                                <div className={member.experience ? styles.memberTraining : styles.hide}><strong>Experience: </strong>{member.experience}</div>
                                <div className={member.training ? styles.memberTraining : styles.hide}><strong>Training: </strong>{member.training}</div>
                                <div className={styles.memberAdditionalInfo}><strong>About me: </strong>{member.additionalInfo}</div>
                            </div>
                        </div>
                        {(index < memberDetails.length - 1 ) && <hr className={styles.divider} />}
                      </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    )
};


export default TeamListings;