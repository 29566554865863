import React from "react";
import styles from './CurriculumDetails.module.scss';
import Header from "../../components/Header/Header";
import CollapsibleSection from "../../components/CollapsibleSection";


const CurriculumDetails = () => {
    return (
        <div className={styles.mainPage}>
            <Header />
            <div className={styles.body}>
                <h3>Curriculum</h3>
                <CollapsibleSection title={"Grade 1"}>
                    <p>
                        First grade is the beginning of formal schooling marked by children’s new interest in learning, inspired by the awakening abilities of memory and thinking. This innate curiosity and desire to grow with a rich multisensory experience by engaging in drawing, moving, listening, imagining, modelling, singing, etc. Essentially, they experience ‘learning’ in different styles that stimulates as well as intrigues the young mind so that they become motivated eager learners. Students establish good habits of classroom life and work that will form the basis for all subsequent learning at school. The students and teacher build the foundation for an ever-deepening relationship while forming a socially cohesive group during this special year of “beginnings.”
                    </p>
                    <strong>Core Subjects</strong>
                    <ul>
                        <li>Meeting the Alphabets and Numbers</li>
                        <li>Word families</li>
                        <li>Introduction to reading and writing</li>
                        <li>Introduction to Arithmetic</li>
                        <li>History and literature: fairy tales, nature stories and folk tales</li>
                        <li>Second Language - Hindi</li>
                        <li>Form drawing</li>
                        <li>Painting</li>
                        <li>Arts and Crafts</li>
                        <li>Handwork: simple weaving and knitting</li>
                        <li>Music: singing and recorder</li>
                        <li>Gardening</li>
                        <li>Games</li>
                    </ul>
                </CollapsibleSection>
                <CollapsibleSection title={"Grade 2"}>
                    <p>
                        This year, children continue to familiarize themselves with the fundamentals of arithmetic and literacy, further developing a repertoire of skills that were initially introduced in grade 1. The curriculum content for this age serves to cultivate a sense of breadth and richness of the language of the feelings and emotions. Teachers continue to use movement, rhythm and rhyme as a key instructional tool.
                    </p>
                    <strong>Core Subjects</strong>
                    <ul>
                        <li>Spelling</li>
                        <li>Reading</li>
                        <li>Writing</li>
                        <li>Introduction to elements of grammar</li>
                        <li>Arithmetic: all four processes</li>
                        <li>History and Literature: Fables and legends</li>
                        <li>Second Language - Hindi</li>
                        <li>Foreign Language</li>
                        <li>Form Drawing</li>
                        <li>Arts</li>
                        <li>Handwork: knitting utilizing various techniques, and beginning crochet</li>
                        <li>Music: singing and recorder</li>
                        <li>Gardening</li>
                        <li>Games</li>
                    </ul>
                </CollapsibleSection>
                <CollapsibleSection title={"Grade 3"}>
                    <p>
                        A noticeable physiological, psychological and cognitive change takes place in the child this year. The nine/ten year threshold represents a very significant step in self-awareness. Children realize they are separate from their surroundings and meet the world as individuals, often resulting in increased questions, self-doubt and wonder.
                        A process begins to unfold through which the child experiences, with increasing strength, a sense of objectivity alongside growing subjectivity. Subjective inner experiences and objective world reality stand at odds within the child’s soul. Questioning, doubt, aloneness and a dawning tendency to criticize are emergent features in the child’s psychological landscape.
                        In this period the child empathizes with stories of the Old Testament, the fall from Eden and man’s first struggles to live in social groups on the earth.
                        At this age, children are very interested in the origin of things. They want to discover new ways of doing things in the world and imagine themselves in very primitive conditions. The practical life is taken up in studies of house building, farming, gardening, cooking and finding out about the jobs people do.
                    </p>
                    <ul>
                        <li>Language Arts: spelling, reading, grammar, cursive writing, introduction to composition</li>
                        <li>Mathematics: multiplication and division, measurement, money and time</li>
                        <li>Housing, farming</li>
                        <li>History: Hebrew & Indian Mythological stories</li>
                        <li>Second Language - Hindi</li>
                        <li>Form drawing</li>
                        <li>Arts</li>
                        <li>Foreign languages - French</li>
                        <li>Handwork</li>
                        <li>Music: singing, and recorder</li>
                        <li>Gardening</li>
                        <li>Games</li>
                    </ul>
                </CollapsibleSection>
                <CollapsibleSection title={"Grade 4"}>
                    <p>
                        This is the period when the fourth grader feels very much separated from any of the security and comfort that previously were supportive. Four represents a sense of steadiness and completion. It is this sense of four, in the midst of separateness and defiance, that is at the very heart of the fourth grade curriculum.
                        This is a time to look around and see how one stands in relationship to that which is near and to find security and uprightness through that relationship. Four is a sign of stability and strength and balance: the four winds, the four seasons, the four elements.
                        In form drawing, Celtic knots are challenging tangles of skill and beauty. The feeling of separateness comes in handy here, otherwise one might get lost in the maze. The theme of separateness is further reflected in the mathematics curriculum with the study of fractions, introduced with concrete objects to demonstrate truths before forming mental concepts.
                        Throughout the year we hear and read stories of heroes. The hero emerges as someone to look up to, emulate, laugh at, respect. There may still be the miraculous feats and yet, the human qualities – the emotions, the struggles and the confrontations – are emphasized; the children understand more than anyone else the hero’s plight to slay the dragon, to woo the maiden, to succeed in the three tasks. In the stories of the Norse Myths, the gods of Asgard are portrayed as individuals with distinct personalities, quite like their own evolving personalities. A comparative local set of mythological stories are also studied.
                        As the children become more aware of the world, the challenges of life may seem overwhelming. The Norse stories give the children the strength to face these challenges.
                    </p>
                    <strong>Core Subjects</strong>
                    <ul>
                        <li>Language Arts: spelling, composition, grammar, cursive writing</li>
                        <li>Mathematics: Introduction to fractions</li>
                        <li>Local geography and history</li>
                        <li>Science: Introduction to plant kingdom</li>
                        <li>History: Morse Myths & Saga</li>
                        <li>Second Language: Hindi</li>
                        <li>Form drawing</li>
                        <li>Arts</li>
                        <li>Foreign language</li>
                        <li>Handwork: Cross stitch</li>
                        <li>Music: instruments and singing, introduction to notations</li>
                        <li>Gardening</li>
                        <li>Games</li>
                    </ul>
                </CollapsibleSection>
                <CollapsibleSection title={"Grade 5"}>
                    <p>
                        This age marks the pivotal point between childhood and puberty and the child attains a certain ease and grace of movement intrinsic to the age. Cognitively, the children are more able to understand questions and phenomena in a realistic and reasoning manner. Memory allows for looking back and planning for the future and, combined with deepening feeling, for the emergence of conscience and responsibility.
                        Fifth graders are enthusiastic about learning, eager for new challenges and capable of hard work and creativity. They still have openness to the world, and a level of confidence that makes them easy to teach.
                        In history, they meet the ancient civilizations of India, Persia, Mesopotamia, Egypt and Greece through the study of mythology and culture. Plant life is the focus of science, and decimals are introduced in the mathematical lessons. Instrumental music, singing and artistic studies continue.
                    </p>
                    <strong>Core Subject</strong>
                    <ul>
                        <li>Language Arts</li>
                        <li>Mathematics</li>
                        <li>Introduction to India’s geography</li>
                        <li>History of ancient civilizations</li>
                        <li>Science: Introduction to plant kingdom</li>
                        <li>Second Language - Hindi</li>
                        <li>Arts</li>
                        <li>Freehand Geometry drawings</li>
                        <li>Foreign languages</li>
                        <li>Handwork: Stippling (introductory woodwork)</li>
                        <li>Woodwork: Introduction</li>
                        <li>Music: instruments and singing</li>
                        <li>Gardening</li>
                    </ul>
                </CollapsibleSection>
                <CollapsibleSection title={"Grade 6"}>
                    <p>
                        In the sixth grade, the harmony and balance often experienced in the previous year fades, and pre-adolescent struggles begin to appear. Physically, the child’s limbs are suddenly growing. Emotionally, the child is at times feeling critical, uncomfortable and longs to be part of a group. The 12-year-old witnesses what may be described as the death of childhood and the birth pangs of the individual.
                        At this age, the teacher aims to work with the children’s growing orientation towards the outer world. As new capacities for thinking emerge, the children can be led to understand causal relationships at work in the world. The students can be challenged and are capable of high standards in their school work.
                        Sixth grade is the gateway to preadolescence and idealism. The curriculum serves to ground the students, to inspire them to venture out toward the unknown and to offer an introduction to their quest in life. This year is both an ending and a beginning.
                    </p>
                    <strong>Core Subjects</strong>
                    <ul>
                        <li>Language Arts</li>
                        <li>Reports and independent projects</li>
                        <li>Mathematics: business math, pre-algebra, geometry</li>
                        <li>History: Greek & Roman History & parallel Indian History</li>
                        <li>Geography: Map making</li>
                        <li>Physics</li>
                        <li>Geology</li>
                        <li>Astronomy</li>
                        <li>Geometric drawing</li>
                        <li>Second Language – Hindi</li>
                        <li>Regional Language - Marathi</li>
                        <li>Arts</li>
                        <li>Foreign languages</li>
                        <li>Handwork</li>
                        <li>Music: instruments and singing</li>
                        <li>Gardening</li>
                        <li>Games</li>
                    </ul>
                </CollapsibleSection>
                <CollapsibleSection title={"Grade 7"}>
                    <p>
                        In grade 7, the pupils turn 13, become teenagers and enter puberty. The visible physiological changes and rapid growth are accompanied by psychological upheaval at least as disorienting and often more so. In both realms, the child loses whatever balance and tranquility they have acquired in the heart of childhood.
                        An appetite for knowledge of, and about, world phenomena, mingles with an emerging capacity for reflection and the first promptings of self reflection. The physical changes which establish sexual identity and capacity begin to manifest more clearly. These physical changes tend to appear in advance of the psychological development. While a feeling and yearning for independence and solitude may be experienced, a certain anxiety, emotional sensitivity and embarrassment can run alongside. Sporadic bursts of energy and an appetite for expanding outer horizons compete with periods of lethargic heaviness and subdued introspection.
                        Through the exploration of an unknown world, the seventh grade curriculum challenges the thought processes of the young adolescent, leading him/her to discovery, understanding and discernment. They learn, as the Explorers did, that going one’s own way means leaving behind the familiar territory of security and stability.
                    </p>
                    <strong>Core Subjects</strong>
                    <ul>
                        <li>Literary Arts</li>
                        <li>Independent projects and reports</li>
                        <li>Human physiology</li>
                        <li>Mathematics: algebra and geometric theorems</li>
                        <li>History: Medieval Ages, Renaissance & parallel Indian History</li>
                        <li>Geography: Earth, continent, zones and climate</li>
                        <li>Physics</li>
                        <li>Chemistry</li>
                        <li>Arts</li>
                        <li>Perspective drawing</li>
                        <li>Creative writing block (wish, wonder, surprise)</li>
                        <li>Foreign language</li>
                        <li>Handwork</li>
                        <li>Music: instruments and singing</li>
                        <li>Gardening</li>
                        <li>Games</li>
                    </ul>
                </CollapsibleSection>
                <CollapsibleSection title={"Grade 8"}>
                    <p>
                        Now the pupils are “into” adolescence: bodily and psychological changes are well under way, so that in general, the young person seems more robust and the tenderness of the previous two years has lessened to some degree. World of ideas begins to take on meaning for them and they are noticeably sharper. Counter balancing this critical tendency is the emergence of reasoning or a “reasonable” side in the child.
                        An eighth grader experiences a gradual but significant shift towards the mutual consideration of a subject by teacher and class together. A sense of community develops, in which speaking becomes more thoughtful, listening more attentive.
                        The result is a greater sense of self. The students expand their sense of place in the world. More importantly, they leave with compelling questions that will continue to fuel their love of learning in the years ahead.
                    </p>
                    <strong>Core Subjects</strong>
                    <ul>
                        <li>Literary Arts</li>
                        <li>Independent projects</li>
                        <li>Anatomy</li>
                        <li>Chemistry</li>
                        <li>Mathematics: algebra and geometry</li>
                        <li>History: Reformations, Revolutions, Industrialisation & Parallel Indian history</li>
                        <li>Geography</li>
                        <li>Meteorology</li>
                        <li>Physics</li>
                        <li>Second Language - Hindi</li>
                        <li>Arts</li>
                        <li>Foreign language</li>
                        <li>Handwork</li>
                        <li>Woodwork</li>
                        <li>Music: instrument and singing</li>
                        <li>Gardening</li>
                        <li>Games</li>
                    </ul>
                </CollapsibleSection>
            </div>
        </div>
    )
}

export default CurriculumDetails;

