import React from "react";
import styles from './Team.module.scss';
import Header from "../../components/Header/Header";


const Team = () => {

    return (
      <div className={styles.mainPage}>
          <Header />
          <div className={styles.body}>
            <div className={styles.teamBannerPhoto}>
              <img src="/assets/teambanner.png" alt="banner"/>
            </div>
            <div className={styles.teamMainContent}>
                <div className={styles.teamTitle}>The Team</div>
                <div className={styles.teamIntroductionText}>
                    <p>
                        Shishyaa is co-founded by passionate educationists who have come together to create
                        this school that fulfils their vision of what a school stands for. Shishyaa stands to bridge the
                        chasm created by the limiting nature of many of the practices of conventional education
                        systems, and the restrictive world-view that it gives the students.
                    </p>
                    <p>The Core Team that manages the school includes Ms. Bandana Basu and Ms. Reena Dhila.</p>
                </div>
                <div className={styles.founderContent}>
                    <a href="/founders" className={styles.founderTitle}>Founders</a>
                </div>
                <div className={styles.staffContent}>
                    <a href="/staff" className={styles.staffTitle}>Staff</a>
                </div>
            </div>
          </div>
      </div>
    )
};

export default Team;