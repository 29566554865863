import React from "react";
import {Switch, Route, BrowserRouter} from "react-router-dom";
import Homepage from "../pages/Home";
import About from "../pages/About";
import Admission from '../pages/Admissions';
import Team from '../pages/Team';
import Preschool from '../pages/Academics_Preschool';
import Mission from "../pages/Mission";
import Significance from "../pages/Significance";
import Gradeschool from '../pages/Academics_Gradeschool';
import Farming from '../pages/Academics_Farming';
import Education from '../pages/Academics_Why_Education';
import Events from "../pages/Events";
import Founders from "../pages/Founders";
import Staff from "../pages/Staff";
import CurriculumDetails from "../pages/Curriculum_Details";
import Shishya_Story from "../pages/Shishya_Story";

const Content = () => {
    return (
        <BrowserRouter>
            <Switch>
              <Route exact path="/glimpses" component={About} />
              <Route exact path="/" component={Homepage} />
              <Route exact path="/mission" component={Mission} />
              <Route exact path="/admission" component={Admission} />
              <Route exact path="/team" component={Team} />
              <Route exact path="/preschool" component={Preschool} />
              <Route exact path="/philosophy" component={Mission} />
              <Route exact path="/significance" component={Significance} />
              <Route exact path="/gradeschool" component={Gradeschool} />
              <Route exact path="/farming" component={Farming} />
              <Route exact path="/why_education" component={Education} />
              <Route exact path="/events" component={Events} />
              <Route exact path="/founders" component={Founders} />
              <Route exact path="/faculty" component={Staff} />
              <Route exact path="/curriculum" component={CurriculumDetails} />
              <Route exact path="/about-us" component={Shishya_Story} />
            </Switch>
        </BrowserRouter>
    )
};

export default Content;
