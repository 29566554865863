    import React from "react";
import Carousel from "../../components/Carousel";
import styles from './Admissions.module.scss';
import Header from "../../components/Header/Header";


const CAROUSEL_IMAGES = [
    "/assets/admission_car1.jpeg",
    "/assets/admission_car2.jpeg",
    "/assets/admission_car3.jpeg",
    "/assets/admission_car4.jpeg",
    "/assets/admission_car5.jpeg",
];

const Admissions = () => {

    return (
      <div className={styles.mainPage}>
          <Header />
          <div className={styles.body}>
            <div className={styles.admissionBannerPhoto}>
                <Carousel images={CAROUSEL_IMAGES} />
            </div>
            <div className={styles.admissionsMainContent}>
                <div className={styles.admissionsTitle}>Admissions</div>
                <div className={styles.admissionsContent}>
                    <p className={styles.firstContentPara}>
                        Admission Forms for the up-coming year 2023-24 are available at the school office. Strength per
                        class is 20 students only.
                    </p>
                    <div className={styles.eligibilityCriteria}>
                        <strong> Eligibility Criteria:</strong>
                        <table>
                          <tbody>
                            <tr>
                                <td>Playgroup</td>
                                <td>- 2 years (24 months) completed as on 1st July.</td>
                            </tr>
                            <tr>
                                <td>Nursery</td>
                                <td>- 3 years completed as on 1st June.</td>
                            </tr>
                            <tr>
                                <td>Lower KG</td>
                                <td>- 4 years completed as on 1st June.</td>
                            </tr>
                            <tr>
                                <td>Upper KG</td>
                                <td>- 5 years completed as on 1st June.</td>
                            </tr>
                            <tr>
                                <td>Grade 1</td>
                                <td>- 6 years completed as on 1st June.</td>
                            </tr>
                            <tr>
                                <td>Grade 2</td>
                                <td>- 7 years completed as on 1st June.</td>
                            </tr>
                            <tr>
                                <td>Grade 3</td>
                                <td>- 8 years completed as on 1st June.</td>
                            </tr>
                            <tr>
                                <td>Grade 5</td>
                                <td>- 10 years completed as on 1st June.</td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    <div className={styles.procedure}>
                        <ul>
                            <li>Call / Write to us expressing an interest to admit your child to our school.</li>
                            <li>An appointment is fixed for you to visit the school.</li>
                            <li>Purchase the admission form from the office.</li>
                            <li>Submit the duly filled form along with the required documents and photographs and pay the fee.</li>
                            <li>Call / Write to us expressing an interest to admit your child to our school.</li>
                        </ul>
                    </div>
                    <div className={styles.admissionGeneralNotice}>
                        <p>
                            Do take prior appointment if you wish to visit the school, to ensure personalised attention.
                        </p>
                        <table>
                          <tbody>
                            <tr>
                                <th>Visiting hours</th>
                                <th>- Week days</th>
                                <th>- 12:30pm to 3:00pm only</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th>Saturdays</th>
                                <th>-11:30am to 1:00pm only</th>
                            </tr>
                          </tbody>

                        </table>
                    </div>
                    <div className={styles.admissionsFooter}>
                        <p>
                            Group orientation programmes are organised regularly. You are welcome to participate with
                            spouse and friends to learn about the school. Call/mail to reserve your seat.
                        </p>
                        <div className={styles.admissionsContact}>
                            <strong>Contact:</strong>
                            <span>Bandana Basu - 9930220983 / Reena Dhila - 9821626003</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.form}>
                <iframe
                    id="JotFormIFrame-211391763865059"
                    title="Shishyaa - Inquiry form"
                    onLoad={window.parent.scrollTo(0,0)}
                    allowFullScreen={true}
                    allow="geolocation"
                    src="https://form.jotform.com/211391763865059"
                    frameBorder="0"
                    style={{"minWidth": "100%", "height": "100%", "border":"none"}}
                    scrolling="yes"
                ></iframe>
            </div>
          </div>
      </div>
    )
};

export default Admissions;
