import React from "react";
import styles from "./Staff.module.scss";
import Header from '../../components/Header/Header';
import TeamListings from "../../components/TeamListings";


const TEAM_LISTING_CONTENT = [
        {
        "imgSrc": "/assets/bandana_basu_founder.png",
        "imgAltDescr": "Bandana Basu",
        "name": "Ms. Bandana Basu",
        "role": "Class teacher of Grade 8",
        "qualification": "M.Com., B.Ed.",
        "training": "Waldorf Grades Trained",
        "additionalInfo": "Listening to poems and songs and live in the " +
          "magic of words fills my world with joy and contentment." +
          " Inviting and inspiring children to meet this imaginative and " +
          "soul-rich world is my deepest endeavour."
    },
    {
        "imgSrc": "/assets/reena.jpeg",
        "imgAltDescr": "Ms. Reena Dhila",
        "name": "Ms. Reena Dhila",
        "role": "Kindergarten teacher and Head of Preschool",
        "qualification": "M.A., B.Ed.",
        "training": "Waldorf Early Childhood Kindergarten trained teacher",
        "experience": "8 years of experience working as Kindergarten teacher. Also Hindi and Gardening teacher of Grade School",
        "additionalInfo": "Naturalist at core, my heart beats for anything innocent. " +
            "I spend most of my time either amidst nature or in the company of children"
    },
    {
        "imgSrc": "/assets/padmja.jpg",
        "imgAltDescr": "Ms. Padmaja Rao Ayalasomayajula",
        "name": "Ms. Padmaja Rao Ayalasomayajula",
        "role": "Assistant teacher of Grade 8, Singing Teacher",
        "qualification": "M.Pharm (Pharmacology)",
        "training": "Art of Education Teacher Training programme at Shishyaa.",
        "additionalInfo": "I love singing and dancing. I enjoy spending time with children " +
            "and wish to be able support them in their life journey."
    },
    {
        "imgSrc": "/assets/deena_miss.jpg",
        "imgAltDescr": "Ms. Deena Jagasia",
        "name": "Ms. Deena Jagasia",
        "role": "Class Teacher of Grade 5",
        "qualification": "B.Com, PGID, IATA FIATA",
        "training": "Art of Education Teacher Training programme at Shishyaa, Grades Training at Bodhi Vidyalaya & Shishyaa",
        "experience": "I have worked at Bodhi Vidyalaya previously",
        "additionalInfo": "I am glad that my hobby is part of the Grade curriculum, " +
            "because when I'm not telling 'tales' to my kids, I'm reading them. " +
            "These tales are sometimes also inspired by my love of travelling."
    },
    {
        "imgSrc": "/assets/shivani.jpeg",
        "imgAltDescr": "Ms. Shivani Singh",
        "name": "Ms. Shivani Singh",
        "role": "Science and Wet on Wet Painting Teacher",
        "qualification": "B.E (Biomedical)",
        "training": "Waldorf  Early Childhood Teacher Training from Rainbow Bridge, Currently pursuing Biography training.",
        "experience": "Class teacher for Grade 1 through 3",
        "additionalInfo": "A seeker at heart, I love working with children and assisting them on their journey of " +
                            "self discovery. Bringing them the world of colours through painting is both a joy and a gift."
    },
    {
        "imgSrc": "/assets/Neha.jpeg",
        "imgAltDescr": "Ms. Neha Devendra Dikonda",
        "name": "Ms. Neha Devendra Dikonda",
        "role": "Assistant teacher",
        "qualification": "Graduate. Pursuing M.Com",
        "training": "Upskilling programmes at Shishyaa",
        "additionalInfo": "I love to be around children. I believe I am unique and creative in my own way. " +
            "I love being in nature. I am always open to learn new skills. I can speak 5 different " +
            "languages (English, Hindi, Marathi, Gujarati, Telugu)."
    },
    {
        "imgSrc": "/assets/Ramya.png",
        "imgAltDescr": "Ms. Ramya Seshadri",
        "name": "Ms. Ramya Seshadri",
        "role": "Movement & Eurythmy",
        "qualification": "Post Graduate in Performing Arts",
        "training": "Diploma in Eurythmy, Director of Sankirna Dance Academy",
        "additionalInfo": "At the age of 16 I realised I loved to move, to dance - the entire process of creating a show." +
            "Through the years, I realised how much the sparkle in children's eyes made me grateful for life every single day." +
            "And then I realised that Waldorf education offers us the chance to enjoy that as teachers (and adults associated to it) every time we engage with our lesson.\n" +
            "So I stayed put!" +
            "When I'm not busy looking at the world through the eyes of the children, I'm usually on a trip somewhere or enjoying the company of my loved ones - human and animal!"

    },
    {
        "imgSrc": "/assets/Vaishali_More.jpeg",
        "imgAltDescr": "Ms. Vaishali More",
        "name": "Ms. Vaishali More",
        "role": "Teacher",
        "qualification": "Bachelor of Arts",
        "training": "Nursery Creche Teacher Training & Diploma Course, Waldorf Early Childhood Education Course",
        "additionalInfo": "I love being with children, the most innocent souls. I also love music, singing & dancing"

    },
    {
        "imgSrc": "/assets/madhuri.jpg",
        "imgAltDescr": "Ms. Madhuri Sunil",
        "name": "Ms. Madhuri Sunil",
        "role": "Faculty Co-ordinator",
        "qualification": "M.Com (Management)",
        "training": "Citibank Service Quality, Teacher Training Course From Tree House, Art of" +
            "Education Teacher Training programme at Shishyaa, ISO 9001:2001",
        "additionalInfo": "Everyone tries their best to learn from every experience they have. I am" +
            "no different. Waldorf School helps me rekindle my journey."

    },
    {
        "imgSrc": "/assets/priya.jpg",
        "imgAltDescr": "Ms. Priya Khalap",
        "name": "Ms. Priya Khalap",
        "role": "Class Teacher Grade 1, Marathi teacher and Kathak Teacher ",
        "qualification": "B.Com, Post Graduation Diploma in Financial Management",
        "training": "Art of Education Teacher Training programme at Shishyaa, Pursuing " +
            "Kathak – Madhyama Purna",
        "additionalInfo": "An avid reader of Marathi literature, keen to learn and explore Indian " +
            "Classical dance forms and a passionate educator who likes to teach and guide " +
            "children through games and stories. Also Committed to taking a hands-on approach " +
            "to helping students learn Marathi language through a comprehensive immersion in " +
            "the culture."

    },
    {
        "imgSrc": "/assets/suman.jpg",
        "imgAltDescr": "Ms. Suman Arvind Srivastav",
        "name": "Ms. Suman Arvind Srivastav",
        "role": "Hindi teacher",
        "qualification": "B.Sc, B.Ed",
        "training": "Art of Education Teacher Training programme at Shishyaa",
        "additionalInfo": "I like to be with children, to teach them Hindi, the national language of " +
            "our nation. I enjoy teaching children through drawing and activities where I engage " +
            "my students in language learning. I ‘ve bagged accolades in creative arts."

    },
    {
        "imgSrc": "/assets/hemanshi.jpeg",
        "imgAltDescr": "Ms Hemanshi Hinduja",
        "name": "Ms Hemanshi Hinduja",
        "role": "French Teacher",
        "qualification": "Bachelor of Commerce, French DELF B2",
        "training": "Jolly Phonics Workshops, French conversation Workshops, Art of " +
            "Education Teacher Training programme at Shishyaa",
        "additionalInfo": "Like most worthwhile endeavours, teaching brings both, challenges and " +
            "rewards. And I love to teach because in my classroom the child in me also " +
            "celebrates. The greatest joy for me as a teacher is the moment when I know my " +
            "students have understood the lesson. Outside the classroom, I sustain my happiness " +
            "by thinking of ways to make learning fun."

    },
    {
        "imgSrc": "/assets/sneha.jpg",
        "imgAltDescr": "Ms Sneha Arbune",
        "name": "Ms Sneha Arbune",
        "role": "Grade 3 Class Teacher",
        "qualification": "BTech (Production Engg, VJTI), MDes (Product Design IISc)",
        "training": "Art of Education Teacher Training programme at Shishyaa",
        "additionalInfo": "I have always had a strong urge to learn and make new things. My " +
            "motherhood led me to discover Waldorf world and each day, I am continuously " +
            "discovering new aspects of education and life."

    },
    {
        "imgSrc": "/assets/rizwana.jpg",
        "imgAltDescr": "Ms Rizwana Shaikh",
        "name": "Ms Rizwana Shaikh",
        "role": "Grade 2 Class Teacher",
        "qualification": "M.Com",
        "training": "Grade 2 Training",
        "additionalInfo": "I am a person who holds a deep appreciation for art and embraces a tranquil," +
            " uncomplicated existence. I find great joy in spending time with children and cherishing" +
            " those moments."

    },
    {
        "imgSrc": "/assets/niyati.jpg",
        "imgAltDescr": "Ms Niyati Pawar",
        "name": "Ms Niyati Pawar",
        "role": "Handwork Teacher from Grade 1 to 8",
        "qualification": "B.A",
        "training": "One year Montessori teacher Training Course",
        "additionalInfo": "I derive immense satisfaction from engaging " +
            "with children and actively participating in their development." +
            " I take pleasure in various artistic endeavors such handicrafts," +
            " drawing, singing, and creating crochet jewellery."

    },
    {
        "imgSrc": "/assets/shweta.jpg",
        "imgAltDescr": "Ms Shweta Suryavanshi",
        "name": "Ms Shweta Suryavanshi",
        "role": "Grade 4 Class Teacher",
        "qualification": "Master of Life Sciences (Biotechnology), " +
            "Senior Research Fellow with BARC, Certified Career Analyst",
        "training": "Grade 4 Training",
        "additionalInfo": "All kind of education culminates into discovery of" +
            " something about oneself or a solution to something perceived as" +
            " a problem. This interwoven nature of complex and simple, makes " +
            "life worthy. It is my child who made me realise I can be everything" +
            " I can ever imagine myself to be. It is this innocence I strive to" +
            " live and breathe into education once again, this time through the" +
            " eyes of children."

    }

];

const Staff = () => {
  return (
    <div className={styles.mainPage}>
      <Header/>
      <div className={styles.body}>
        <div className={styles.founderTitle}>Faculty</div>
        <TeamListings memberDetails={TEAM_LISTING_CONTENT} />
      </div>
    </div>
  )
};

export default Staff;
