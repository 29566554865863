import React from "react";
import styles from './About.module.scss';
import Header from "../../components/Header";

const About = () => {

    return (
        <div className={styles.mainPage}>
            <Header />
            <div className={styles.body}>
                <div className={styles.glimpsesContent}>
                    <div className={styles.glimpsesBannerImage}>
                        <img src="/assets/banner_2.png" alt="banner" />
                    </div>
                    <div className={styles.glimpsesMainContent}>
                        <div className={styles.glimpsesTitle}>Glimpses of Shishyaa - Insights</div>
                        <div className={styles.glimpsesContentOne}>
                            <div className={styles.glimpsesVideos}>
                                <div className={styles.videoOne}>
                                    <iframe src="https://www.youtube.com/embed/wfec6eF4I_4"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen title="shishyaa-video-1">
                                    </iframe>
                                </div>
                                <div className={styles.videoTwo}>
                                    <iframe src="https://www.youtube.com/embed/B-ZSeepDmPE"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen title="shishyaa-video-2">
                                    </iframe>
                                </div>
                                <div className={styles.videoThree}>
                                    <iframe src="https://www.youtube.com/embed/nJjSbH2kO2Y"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen title="shishyaa-video-3">
                                    </iframe>
                                </div>
                            </div>
                            <div className={styles.glimpsesText}>
                                <p>
                                    Shishyaa is an international school with the vision of
                                    an empowered future. Here, students experience
                                    day to day life in a meaningful way, by participating
                                    and 'doing' purposeful activities. Learning through
                                    tangible experiences, allow children to create their
                                    own knowledge, and empowers them to become
                                    independent learners, which is an essential life-skill.
                                    Being a 'shishyaa', means being humble and
                                    receptive to all the knowledge that life has to offer.
                                    It is an attitude towards life.
                                </p>
                                <p>
                                    Being committed to creating individuals with
                                    resilience, and an unending capacity to perform as
                                    well as recreate themselves under all conditions, we
                                    integrate challenging but sustainable best practices
                                    like recycled furniture, eco-friendly campus, an
                                    organic farm, and limited classroom strength
                                    amongst the many others.
                                </p>
                                <p>
                                    Being a small school allows us to give undiluted
                                    attention to every child, because every child
                                    matters!! So we house only twenty students per
                                    grade and each grade has only one section.
                                </p>
                                <p>
                                    Waldorf Education methodology, developed by Dr.
                                    Rudolf Steiner, compliments our philosophy of
                                    education and thus we have chosen to join hands
                                    and walk this path together. And hence we are now
                                    a Waldorf School.
                                </p>
                            </div>
                        </div>
                        <div className={styles.glimpsesContentTwo}>
                            <div className={styles.contentTitle}>Less is More</div>
                            <div className={styles.image}>
                                <img src="/assets/glimpses_content.png" alt="glimpses"/>
                            </div>
                        </div>
                        <div className={styles.glimpsesContentThree}>
                            <div className={styles.contentTitle}>Our Intent - Harness the power of "LESS"</div>
                            <div className={styles.contentText}>
                                <p>
                                    The future of this planet rests secure in the hands of those who understand that 'less' is 'more'.
                                    With the vision for the future, we are committed to create individuals with resilience,and an
                                    unending capacity to perform and recreate themselves under all conditions.
                                    To achieve this, we integrate sustainable best practices like recycled furniture, eco-friendly
                                    campus, in-house farm, and limited classroom strength amongst the many others. Breaking free
                                    from the number game, we seek to remain small because we understand that children find
                                    comfort in close bonds. It helps them develop an immediate sense of belonging and assurance,
                                    and they are able to perform their best.
                                </p>
                                <p>
                                    But most of all we endeavour to give our students a secured space to be what they most long to
                                    be.... children.
                                </p>
                                <p>We celebrate their childhood!!!</p>
                            </div>
                        </div>
                        <div className={styles.glimpsesContentFour}>
                            <div className={styles.glimpsesBottomImageOne}>
                              <img src="/assets/glimpses_content_two.png" alt="Glimpses" />
                            </div>
                            <div className={styles.glimpsesQuote}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default About;
